import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Product Development"
      keywords={['gracescale', 'product', 'development']}
    />

    <h2 className="lh-title mt0 mb3 heading f3 f2-l">
      <span className="db-ns">
        We build sustainable digital products for forward thinkers.
      </span>
    </h2>

    <p>
      Let’s be honest, the internet has lost its way. Big tech, digital
      homogeneity, style over substance.
    </p>

    <p>
      We are here to work alongside the people who are in it for the long-run,
      bringing to life ideas that will get the world back up on its feet.
    </p>

    <div className="flex pt3">
      <a
        href="mailto:hello@gracescale.com"
        target="_blank"
        className="button block sans-serif"
      >
        Talk to us
      </a>
    </div>
  </Layout>
)

export default IndexPage
